<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <h1>{{ purchasesInvoice.seq_number ?? purchasesInvoice.code }}</h1>
    </div>

    <div class="row mb-5">
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(purchasesInvoice.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("PURCHASES_INVOICES.EXPIRATION_TIME") }}
          </dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(purchasesInvoice.expiration_time) }}
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CAFETERIAS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.CAFETERIA") }}</dt>
          <dd class="col-sm-8">
            <cafeteria :cafeteria="purchasesInvoice.cafeteria" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ESTABLISHMENTS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.ESTABLISHMENT") }}</dt>
          <dd class="col-sm-8">
            <establishment :establishment="purchasesInvoice.establishment" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SERVICECENTERS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.SERVICECENTER") }}</dt>
          <dd class="col-sm-8">
            <service-center :serviceCenter="purchasesInvoice.serviceCenter" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd class="col-sm-8">
            <locations :locations="purchasesInvoice.allowedLocations" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="purchasesInvoice.organization" />
          </dd>
        </dl>

        <dl class="row" v-if="purchasesInvoice.purchasesOrder">
          <dt class="col-sm-4">{{ $t("COMMON.PURCHASES_ORDER") }}</dt>
          <dd class="col-sm-8">
            <object-link :object="purchasesInvoice.purchasesOrder" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ISSUER") }}</dt>
          <dd class="col-sm-8">
            <object-link :object="purchasesInvoice.issuer" />
          </dd>
        </dl>

        <dl class="row" v-if="purchasesInvoice.destinationWarehouse">
          <dt class="col-sm-4">{{ $t("COMMON.WAREHOUSE") }}</dt>
          <dd class="col-sm-8">
            <object-link :object="purchasesInvoice.destinationWarehouse" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.STATUS") }}</dt>
          <dd class="col-sm-8">
            <purchases-invoice-status-badge
              :purchasesInvoice="purchasesInvoice"
            />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.HAS_TAXES") }}</dt>
          <dd class="col-sm-8">
            <icon-check :checked="!purchasesInvoice.has_no_taxes" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.PAYMENT_TERMS") }}</dt>
          <dd class="col-sm-8">
            <object-link :object="purchasesInvoice.paymentTerm" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.TOTAL") }}</dt>
          <dd class="col-sm-8" v-if="purchasesInvoice.pricing">
            <span> {{ $formatCurrency(purchasesInvoice.pricing.total) }} </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.TOTAL_PAIED") }}</dt>
          <dd class="col-sm-8">
            <span>
              {{
                $formatCurrency(
                  purchasesInvoice.total_paied
                    ? purchasesInvoice.total_paied
                    : 0
                )
              }}
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4" v-if="purchasesInvoice.excerpt">
            {{ $t("COMMON.NOTE") }}
          </dt>
          <dd class="col-sm-8" v-if="purchasesInvoice.excerpt">
            <div v-html="purchasesInvoice.excerpt"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4" v-if="purchasesInvoice.terms_conditions">
            {{ $t("COMMON.TERMS_CONDITIONS") }}
          </dt>
          <dd class="col-sm-8" v-if="purchasesInvoice.terms_conditions">
            <div v-html="purchasesInvoice.terms_conditions"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4" v-if="purchasesInvoice.description">
            {{ $t("COMMON.DESCRIPTION") }}
          </dt>
          <dd class="col-sm-8" v-if="purchasesInvoice.description">
            <div v-html="purchasesInvoice.description"></div>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import Cafeteria from "@/components/Cafeteria.vue";
import Establishment from "@/components/Establishment.vue";
import ServiceCenter from "@/components/ServiceCenter.vue";
import PurchasesInvoiceStatusBadge from "./PurchasesInvoiceStatusBadge.vue";
import IconCheck from "@/components/IconCheck.vue";

export default {
  name: "purchases-invoice-view-global",

  components: {
    PurchasesInvoiceStatusBadge,
    Cafeteria,
    Establishment,
    ServiceCenter,
    IconCheck,
  },

  props: ["purchasesInvoice"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    purchasesInvoice(purchasesInvoice) {},
  },
};
</script>
